import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap"
import "core-js/es6/promise"
import "core-js/es6/set"
import "core-js/es6/map"
import * as yup from "yup"
import TemplateLayout from "../components/Layout/template-layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Styles = styled.div`
  min-height: 800px;
  padding: 6vh 15vw;
  .form-section {
    font-weight: bold;
    padding-top: 35px;
    padding-bottom: 15px;
  }

  .center {
    text-align: center;
  }
`

const defaultForm = {
  title: null,
  firstName: null,
  middleName: null,
  lastName: null,
  sex: null,
  affiliation: null,
  department: null,
  email: null,
  phoneNumber: null,
  computerProgramming1: null,
  computerProgramming2: null,
  softwareEngineering: null,
  softwareTesting: null,
  otherCourses: null,
  java: null,
  cpp: null,
  uml: null,
  otherProgrammingLanguages: null,
  collaborativeLearningKnowledge: null,
  gamificationKnowledge: null,
  socialInteractionKnowledge: null,
  problemBasedLearningKnowledge: null,
  willingToUseOurSystem: null,
  otherRequestedTopicsOfDiscussion: null,
}

let schema = yup.object().shape({
  title: yup.string().required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  sex: yup.string().required("Required"),
  affiliation: yup.string().required("Required"),
  department: yup.string().required("Required"),
  email: yup.string().required(),
  computerProgramming1: yup.string().required("Required"),
  computerProgramming2: yup.string().required("Required"),
  softwareEngineering: yup.string().required("Required"),
  softwareTesting: yup.string().required("Required"),
  java: yup.string().required("Required"),
  cpp: yup.string().required("Required"),
  uml: yup.string().required("Required"),
  collaborativeLearningKnowledge: yup.string().required("Required"),
  gamificationKnowledge: yup.string().required("Required"),
  socialInteractionKnowledge: yup.string().required("Required"),
  problemBasedLearningKnowledge: yup.string().required("Required"),
  willingToUseOurSystem: yup.string().required("Required"),
})

const WorkshopApplication = () => {
  const [errors, setErrors] = useState(defaultForm)
  const [expired, setExpired] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formState, setFormState] = useState(defaultForm)
  const submitForm = async e => {
    e.preventDefault()
    console.log(formState)
    await schema
      .validate(formState, { abortEarly: false })
      .then(valid => {
        if (valid) {
          setFormSubmitted(true)
        }
      })
      .catch(errors => {
        // console.log(errors.inner)
        console.log("form not valid")
        const values = Object.assign({}, errors.values)
        errors.inner.forEach(er => {
          values[er.path] = er.message
        })
        setErrors(values)
      })
  }
  const handleInputChange = async e => {
    const target = e.target

    let value = target.type === "checkbox" ? target.checked : target.value
    value = target.type === "radio" ? target.nextSibling.textContent : value

    const name = target.name

    await validateField(e)

    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateField = async e => {
    const target = e.target

    let value = target.type === "checkbox" ? target.checked : target.value
    value = target.type === "radio" ? target.nextSibling.textContent : value

    const name = target.name

    const t = {}
    t[name] = value

    await yup
      .reach(schema, `${name}`)
      .isValid(value)
      .then(valid => {
        console.log(value)
        if (valid) {
          setErrors({ ...errors, [name]: "" })
        }
      })
    // await schema
    //   .validate(t, { stripUnknown: true, abortEarly: false })
    //   .then(value => {
    //     console.log("onBlur validation success", value)
    //   })
    //   .catch(err => {
    //     console.log("onBlurValidation failure", err)
    //   })
  }

  return (
    <TemplateLayout title="Workshop Application">
      <SEO title="Workshop Application" />
      <Styles>
        <Container>
          <Row>
            <Col>
              {expired && (
                <p className="form-section">
                  The application window for the workshop is now closed.
                </p>
              )}
              {!formSubmitted && !expired && (
                <Form onSubmit={e => submitForm(e)}>
                  <p className="form-section">Profile Information:</p>
                  <FormGroup>
                    <Label for="title">Title:</Label>
                    <Input
                      invalid={errors["title"]?.length > 0}
                      type="select"
                      name="title"
                      id="title"
                      onChange={handleInputChange}
                    >
                      <option></option>
                      <option>Dr.</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                      <option>n/a</option>
                    </Input>
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="firstName">First name</Label>
                    <Input
                      invalid={errors["firstName"]?.length > 0}
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={handleInputChange}
                    />
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="middleName">Middle name</Label>
                    <Input
                      type="text"
                      name="middleName"
                      id="middleName"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="lastName">Last name</Label>
                    <Input
                      invalid={errors["lastName"]?.length > 0}
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={handleInputChange}
                    />
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="sex">Sex</Label>
                    <Input
                      invalid={errors["sex"]?.length > 0}
                      type="select"
                      name="sex"
                      id="sex"
                      onChange={handleInputChange}
                    >
                      <option></option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                      <option>Prefer not to answer</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="affiliation">Affiliation</Label>
                    <Input
                      invalid={errors["affiliation"]?.length > 0}
                      type="text"
                      name="affiliation"
                      id="affiliation"
                      onChange={handleInputChange}
                    />
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="department">Department</Label>
                    <Input
                      invalid={errors["department"]?.length > 0}
                      type="text"
                      name="department"
                      onChange={handleInputChange}
                    />
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      invalid={errors["email"]?.length > 0}
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleInputChange}
                    />
                    <FormFeedback>Required</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="phoneNumber">Phone number</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <p className="form-section">
                    Select course(s) that you have taught or may teach in the
                    future with programming and software modeling content.
                  </p>
                  <FormGroup tag="fieldset">
                    <p>Computer Programming 1</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="computerProgramming1"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                      <FormFeedback>Required</FormFeedback>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="computerProgramming1"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["computerProgramming1"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <p>Computer Programming 2</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="computerProgramming2"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="computerProgramming2"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["computerProgramming2"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <p>Software Engineering</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="softwareEngineering"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="softwareEngineering"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["softwareEngineering"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <p>Software Testing</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="softwareTesting"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="softwareTesting"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["softwareTesting"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="otherCourses">Other Courses</Label>
                    <Input
                      type="textarea"
                      name="otherCourses"
                      id="otherCourses"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="otherCourses">
                      Indicate the IDEs and/or modeling tools used in the
                      courses above:
                    </Label>
                    <Input
                      type="textarea"
                      name="otherCourses"
                      id="otherCourses"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <p className="form-section">
                    Programming and/or Modeling language(s) used in course(s)
                    above:
                  </p>

                  <FormGroup tag="fieldset">
                    <p>Java</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="java"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="java"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["java"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <p>CPP</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="cpp"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="cpp"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["cpp"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <p>UML</p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="uml"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="uml"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                      <FormFeedback
                        style={
                          errors["uml"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="otherProgrammingLanguages">
                      Other Programming Language(s):
                    </Label>
                    <Input
                      type="textarea"
                      name="otherProgrammingLanguages"
                      id="otherProgrammingLanguages"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <p className="form-section">
                    Describe your knowledge or epxeriences using the following
                    LESs in the classroom:
                  </p>
                  <FormGroup>
                    <Label for="collaborativeLearningKnowledge">
                      Collaborative Learning:
                    </Label>
                    <Input
                      invalid={
                        errors["collaborativeLearningKnowledge"]?.length > 0
                      }
                      type="textarea"
                      name="collaborativeLearningKnowledge"
                      id="collaborativeLearningKnowledge"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="gamificationKnowledge">Gamification:</Label>
                    <Input
                      invalid={errors["gamificationKnowledge"]?.length > 0}
                      type="textarea"
                      name="gamificationKnowledge"
                      id="gamificationKnowledge"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="problemBasedLearningKnowledge">
                      Problem-Base Learning:
                    </Label>
                    <Input
                      invalid={
                        errors["problemBasedLearningKnowledge"]?.length > 0
                      }
                      type="textarea"
                      name="problemBasedLearningKnowledge"
                      id="problemBasedLearningKnowledge"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="socialInteractionKnowledge">
                      Social Interaction:
                    </Label>
                    <Input
                      invalid={errors["socialInteractionKnowledge"]?.length > 0}
                      type="textarea"
                      name="socialInteractionKnowledge"
                      id="socialInteractionKnowledge"
                      onChange={handleInputChange}
                    />
                  </FormGroup>

                  <FormGroup tag="fieldset">
                    <p>
                      Are you willing to integrate the use of LESs and SEP-CyLE
                      in the classroom into your course(s) in academic year
                      2020-2021 and beyond:
                    </p>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="willingToUseOurSystem"
                        onChange={handleInputChange}
                      />
                      <Label check>Yes</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="willingToUseOurSystem"
                        onChange={handleInputChange}
                      />
                      <Label check>No</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        type="radio"
                        name="willingToUseOurSystem"
                        onChange={handleInputChange}
                      />
                      <Label check>Need more information</Label>
                      <FormFeedback
                        style={
                          errors["willingToUseOurSystem"]?.length > 0
                            ? {
                                display: "block",
                              }
                            : {}
                        }
                      >
                        Required
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="otherRequestedTopicsOfDiscussion">
                      Other Requested Topics of Discussion:
                    </Label>
                    <Input
                      type="textarea"
                      name="otherRequestedTopicsOfDiscussion"
                      id="otherRequestedTopicsOfDiscussion"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <Button disabled>Submit</Button>
                </Form>
              )}
              {formSubmitted && !expired && (
                <p className="form-section center">
                  Thank you for applying. An email confirming receipt of your
                  application has been sent to the email address provided.
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Styles>
    </TemplateLayout>
  )
}

export default WorkshopApplication
